import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface DataProps {
  labels: string[];
  colors: string[];
  values: number[];
}

const BarChart = ({ labels, colors, values }: DataProps) => {
  const datasets = labels.map((label, index) => ({
    label: label,
    data: [values[index]],
    backgroundColor: colors[index],
    borderColor: "white",
    barThickness: 60,
    barPercentage: 0.8,
  }));

  const data = {
    labels:[""],
    datasets,
  };

  const options = {
    indexAxis: "y" as const,
    elements: {
      bar: {
        borderWidth: 5,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top" as const,
        align: "center" as const,
        labels: {
          boxHeight: 10,
          boxWidth: 20,
        },
      },
    },
    scales: {
      y: {
        display: false,
      },
     
    },
  };

  return (
    <div className="h-[300px] w-[90%]">
      <Bar options={options} data={data} />
    </div>
  );
};

export default BarChart;
