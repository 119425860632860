import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

interface DataProps {
  labels: string[];
  colors: string[];
  values: number[];
}

const PieChart = ({ labels, colors, values }: DataProps) => {
  const data = {
    labels,
    datasets: [
      {
        data: values,
        backgroundColor: colors,
      },
    ],
  };

  const legendOptions = {
    labels: {
      usePointStyle: true,
    },
  };

  return <Pie data={data} options={{ plugins: { legend: legendOptions } }} />;
};

export default PieChart;
