import React, { useState } from "react";

import DropdownMessage from "./DropdownMessage";
import DropdownNotification from "./DropdownNotification";
import DropdownUser from "./DropdownUser";
import DarkModeSwitcher from "./DarkModeSwitcher";
import { LogIn, Menu, UserPlus } from "lucide-react";
import { Link, useLocation } from "react-router-dom";

const Header = (props: {
  sidebarOpen: string | boolean | undefined;
  setSidebarOpen: (arg0: boolean) => void;
}) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const location = useLocation();
  const { pathname } = location;

  // Function to check if the given path is active
  const isActive = (path: string) => {
    return pathname === path;
  };

  return (
    <nav className="sticky flex flex-col top-0 z-[100] w-full  bg-white shadow-md border-gray-200 dark:bg-slate-500 dark:drop-shadow-none dark:border-gray-700">
      {/* Header content */}
      <div className="flex items-center justify-between px-4 py-4 shadow-2 md:px-6 2xl:px-11">
        <div className="flex items-center gap-2 sm:gap-4">
          {/* Hamburger Toggle BTN */}
          <button
            aria-controls="sidebar"
            onClick={(e) => {
              e.stopPropagation();
              props.setSidebarOpen(!props.sidebarOpen);
            }}
            className="z-99999 block rounded-sm border border-stroke bg-white p-1.5 shadow-sm dark:border-strokedark dark:bg-slate-500"
          >
            <span className="relative block h-5.5 w-5.5 cursor-pointer">
              <Menu />
            </span>
          </button>
          {/* <Logo /> */}
        </div>
        <div></div>

        {/* Authentication & Navigation Links */}
        <div className="flex items-center gap-3 2xsm:gap-7">
          {!isLoggedIn ? (
            <ul className="flex items-center gap-2 2xsm:gap-4">
              <li className="relative">
                <Link
                  onClick={() => setIsLoggedIn(!isLoggedIn)}
                  to="#"
                  className="relative flex h-8.5 w-8.5 items-center justify-center px-2 py-1  bg-gray dark:bg-slate-500 dark:text-white"
                >
                  <LogIn className="h-4 w-4 mr-1" />
                  Login
                </Link>
              </li>
              <li className="relative">
                <Link
                  // onClick={() => setIsLoggedIn(!isLoggedIn)}
                  to="#"
                  className="relative flex h-8.5 w-8.5 items-center justify-center px-2 py-1  bg-gray dark:bg-slate-500 dark:text-white"
                >
                  <UserPlus className="h-4 w-4 mr-1" />
                  Register
                </Link>
              </li>
            </ul>
          ) : (
            <>
              <ul className="flex items-center gap-2 2xsm:gap-4">
                <DarkModeSwitcher />
                <DropdownNotification />
                <DropdownMessage />
              </ul>
              <DropdownUser />
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Header;
