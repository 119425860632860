// hello

export const agencyMockData = [
  {
    annualSalary: "50000",
    workableHours: "180",
    buildingAllocation: "10000",
    expenseCost: "5000",
    monthlyWage: "4166.67",
    hourlyRate: "26.04",
    fullyLoadedCost: "57237.5",
    hoursInMonth: "160",
    ptoHours: "16",
    utiWorkableHours: "144",
    trackedHours: "120",
    unTrackedHours: "24",
    trackedPercent: "83.33",
    nonBillableHours: "40",
    nonBillablePercent: "33.33",
    nonBillableCost: "1041.6",
    billableHours: "80",
    billablePercent: "66.67",
    billableCost: "2063.2",
  },
  {
    annualSalary: "50000",
    workableHours: "150",
    buildingAllocation: "10000",
    expenseCost: "5000",
    monthlyWage: "4166.67",
    hourlyRate: "26.04",
    fullyLoadedCost: "57237.5",
    hoursInMonth: "160",
    ptoHours: "16",
    utiWorkableHours: "144",
    trackedHours: "120",
    unTrackedHours: "24",
    trackedPercent: "83.33",
    nonBillableHours: "40",
    nonBillablePercent: "33.33",
    nonBillableCost: "1041.6",
    billableHours: "80",
    billablePercent: "66.67",
    billableCost: "2063.2",
  },
  {
    annualSalary: "50000",
    workableHours: "160",
    buildingAllocation: "10000",
    expenseCost: "5000",
    monthlyWage: "4166.67",
    hourlyRate: "26.04",
    fullyLoadedCost: "57237.5",
    hoursInMonth: "160",
    ptoHours: "16",
    utiWorkableHours: "144",
    trackedHours: "120",
    unTrackedHours: "24",
    trackedPercent: "83.33",
    nonBillableHours: "40",
    nonBillablePercent: "33.33",
    nonBillableCost: "1041.6",
    billableHours: "80",
    billablePercent: "66.67",
    billableCost: "2063.2",
  },
];
