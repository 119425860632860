import axios from "axios";
import React, { useState, useEffect, ChangeEvent, MouseEvent } from "react";
import constants from "../../common/constants";

interface User {
  id: number;
  name: string;
  email: string;
  department: string;
  department_id: string;
  employee_level: "Employee" | "Director" | "VP" | "C-Suite";
}

interface Department {
  key: string;
  value: string;
}

const TableComponent: React.FC = () => {
  const [data, setData] = useState<User[]>([]);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [companies, setCompanies] = React.useState<Department[]>([]);
  const [editRowId, setEditRowId] = useState<number | null>(null);
  const [editFormData, setEditFormData] = useState<Partial<User>>({
    name: "",
    email: "",
    department: "",
    employee_level: "Employee",
  });

  useEffect(() => {
    // Fetch data from API using Axios
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${constants.config.apiBaseUrl}/api/v1/users/paymo`
        );
        setData(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchDepartments = async () => {
      try {
        const response = await axios.get(
          `${constants.config.apiBaseUrl}/api/v1/company/departments?as_drop=true`
        );
        setDepartments(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    fetchDepartments();
  }, []);

  React.useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axios.get(
          `${constants.config.apiBaseUrl}/api/v1/company?as_drop=true`
        );
        setCompanies(response.data.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchCompanies();
  }, []);
  const handleEditClick = (event: MouseEvent<HTMLButtonElement>, row: User) => {
    event.preventDefault();
    setEditRowId(row.id);

    const formValues = {
      name: row.name,
      department: row.department,
      employee_level: row.employee_level,
      department_id: row.department_id,
    };

    setEditFormData(formValues);
  };

  const handleEditFormChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    console.log(name, value);
    const newFormData = { ...editFormData };
    (newFormData as any)[name] = value;

    setEditFormData(newFormData);
  };

  const handleSaveClick = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    // Add your save logic here, e.g., update the backend or state
    console.log("Saving data:", editFormData);

    try {
      // Update the user in the backend using Axios
      await axios.put(
        `${constants.config.apiBaseUrl}/api/v1/users/paymo/${editRowId}`,
        {
          department_id: Number(editFormData.department_id),
          employee_level: editFormData.employee_level,
        }
      );

      // Update the local state
      setData((prevData) =>
        prevData.map((user) =>
          user.id === editRowId ? { ...user, ...editFormData } : user
        )
      );

      setEditRowId(null);
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const handleCancelClick = () => {
    setEditRowId(null);
  };

  const getDepartmentById = (departmentId: string) => {
    const department = departments.find((d) => d.key === departmentId);
    if (!department) {
      return undefined;
    }
    const company = companies.find((c) => c.key === department.key);
    if (!company) {
      return undefined;
    }
    return `${company.value} - ${department.value}`;
  };

  return (
    <table className="table-auto w-full border-collapse">
      <thead>
        <tr className="bg-gray-200 text-left">
          <th className="py-2 px-4 border-b">Name</th>
          <th className="py-2 px-4 border-b">Email</th>
          <th className="py-2 px-4 border-b">Department</th>
          <th className="py-2 px-4 border-b">Employee Level</th>
          <th className="py-2 px-4 border-b">Actions</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row) => (
          <tr key={row.id} className="hover:bg-gray-100">
            {editRowId === row.id ? (
              <>
                <td className="py-2 px-4 border-b">{row.name}</td>
                <td className="py-2 px-4 border-b">{row.email}</td>
                <td className="py-2 px-4 border-b">
                  <select
                    name="department_id"
                    value={editFormData.department_id || ""}
                    onChange={handleEditFormChange}
                    className="w-full px-3 py-1 border rounded"
                  >
                    <option value="">Select Department</option>
                    {departments.map((department) => (
                      <option key={department.key} value={department.key}>
                        {getDepartmentById(department.key)}
                      </option>
                    ))}
                  </select>
                </td>
                <td className="py-2 px-4 border-b">
                  <select
                    name="employee_level"
                    value={editFormData.employee_level}
                    onChange={handleEditFormChange}
                    className="w-full px-3 py-1 border rounded"
                  >
                    <option value="Employee">Employee</option>
                    <option value="VP">VP</option>
                    <option value="Director">Director</option>
                    <option value="C-Suite">C-Suite</option>
                  </select>
                </td>
                <td className="py-2 px-4 border-b">
                  <button
                    type="button"
                    onClick={handleSaveClick}
                    className="mr-2 bg-blue-500 text-white px-4 py-2 rounded"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    onClick={handleCancelClick}
                    className="bg-gray-500 text-white px-4 py-2 rounded"
                  >
                    Cancel
                  </button>
                </td>
              </>
            ) : (
              <>
                <td className="py-2 px-4 border-b">{row.name}</td>
                <td className="py-2 px-4 border-b">{row.email}</td>
                <td className="py-2 px-4 border-b">
                  {getDepartmentById(row.department_id)}
                </td>
                <td className="py-2 px-4 border-b">{row.employee_level}</td>
                <td className="py-2 px-4 border-b">
                  <button
                    type="button"
                    onClick={(event) => handleEditClick(event, row)}
                    className="bg-indigo-500 text-white px-4 py-2 rounded"
                  >
                    Edit
                  </button>
                </td>
              </>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

// export default TableComponent;
export default TableComponent;
