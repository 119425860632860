import React, { ChangeEvent, MouseEvent, FormEvent } from "react";
import { CirclePlus } from "lucide-react";
import { Modal } from "../../common/ui/modal";
import { Button } from "../../common/ui/button";
import axios from "axios";
import constants from "../../common/constants";

interface Company {
  id: number;
  name: string;
  building_allocation_year: string;
}

const CompanyList: React.FC = () => {
  const [data, setData] = React.useState<Company[]>([]);
  const [isOpen, setOpen] = React.useState<boolean>(false);
  const [editRowId, setEditRowId] = React.useState<number | null>(null);
  const [formData, setFormData] = React.useState<Partial<Company>>({
    building_allocation_year: "",
    name: "",
  });

  React.useEffect(() => {
    // Fetch data from API using Axios
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${constants.config.apiBaseUrl}/api/v1/company`
        );
        setData(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleEditClick = (
    event: MouseEvent<HTMLButtonElement>,
    row: Company
  ) => {
    event.preventDefault();
    setEditRowId(row.id);

    const formValues = {
      name: row.name,
      building_allocation_year: row.building_allocation_year,
    };

    setFormData(formValues);
  };

  const handleEditFormChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    console.log(name, value);
    const newFormData = { ...formData };
    (newFormData as any)[name] = value;

    setFormData(newFormData);
  };

  const handleSaveClick = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    console.log("Saving data:", formData);

    try {
      await axios.put(
        `${constants.config.apiBaseUrl}/api/v1/company/${editRowId}`,
        {
          name: formData.name,
          building_allocation_year: formData.building_allocation_year,
        }
      );

      setData((prevData) =>
        prevData.map((department) =>
          department.id === editRowId
            ? { ...department, ...formData }
            : department
        )
      );

      setEditRowId(null);
      setFormData({ building_allocation_year: "", name: "" });
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    console.log("Form Data Submitted:", formData);
    // Perform your form submission logic here
    const response = await axios.post(
      `${constants.config.apiBaseUrl}/api/v1/company`,
      {
        name: formData.name,
        building_allocation_year: formData.building_allocation_year,
      }
    );
    console.log("Response : ", response);
    if (response && response.data) {
      setData((prevData) => {
        prevData.unshift(response.data.data);
        return prevData;
      });
      setOpen(false);
    }
  };

  const handleCancelClick = () => {
    setEditRowId(null);
    setFormData({ building_allocation_year: "", name: "" });
  };

  return (
    <div>
      <Modal
        title={"Create Company"}
        description=""
        isOpen={isOpen}
        onClose={() => {
          setFormData({ building_allocation_year: "", name: "" });
          setOpen(false);
        }}
      >
        <div className="flex justify-center items-center w-full ">
          <form
            onSubmit={handleSubmit}
            className="bg-white p-6 rounded shadow-md w-full max-w-md"
          >
            <div className="mb-4">
              <label
                htmlFor="name"
                className="block text-gray-700 font-bold mb-2"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="building_allocation_year"
                className="block text-gray-700 font-bold mb-2"
              >
                Building Allocation/Month
              </label>
              <input
                type="text"
                id="building_allocation_year"
                name="building_allocation_year"
                value={formData.building_allocation_year}
                onChange={handleChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="flex items-center justify-between">
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <div className="flex border-b pb-2 items-center justify-between">
        <div className="mb-3 flex justify-center">
          <span className="text-lg font-semibold">Department</span>
        </div>
        <div>
          <Button
            size="sm"
            className={`bg-cyan-500 hover:bg-cyan-500`}
            onClick={() => setOpen(true)}
            title="disabled"
          >
            <CirclePlus className="mr-2 h-4 w-4" />
            Add Company
          </Button>
        </div>
      </div>
      <table className="table-auto w-full border-collapse">
        <thead>
          <tr className="bg-gray-200 text-left">
            <th className="py-2 px-4 border-b">Name</th>
            <th className="py-2 px-4 border-b">Building Allocation/Month</th>
            <th className="py-2 px-4 border-b">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr key={row.id} className="hover:bg-gray-100">
              {editRowId === row.id ? (
                <>
                  <td className="py-2 px-4 border-b">{row.name}</td>
                  <td className="py-2 px-4 border-b">
                    <input
                      type="text"
                      name="expense_cost_per_month"
                      value={formData.building_allocation_year}
                      onChange={handleEditFormChange}
                      className="w-full px-3 py-1 border rounded"
                    />
                  </td>
                  <td className="py-2 px-4 border-b">
                    <button
                      type="button"
                      onClick={handleSaveClick}
                      className="mr-2 bg-blue-500 text-white px-4 py-2 rounded"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      onClick={handleCancelClick}
                      className="bg-gray-500 text-white px-4 py-2 rounded"
                    >
                      Cancel
                    </button>
                  </td>
                </>
              ) : (
                <>
                  <td className="py-2 px-4 border-b">{row.name}</td>
                  <td className="py-2 px-4 border-b">
                    {row.building_allocation_year}
                  </td>

                  <td className="py-2 px-4 border-b">
                    <button
                      type="button"
                      onClick={(event) => handleEditClick(event, row)}
                      className="bg-indigo-500 text-white px-4 py-2 rounded"
                    >
                      Edit
                    </button>
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CompanyList;
