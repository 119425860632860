import React, { ChangeEvent, MouseEvent, FormEvent } from "react";
import { CirclePlus } from "lucide-react";
import { Button } from "../../common/ui/button";
import axios from "axios";
import constants from "../../common/constants";
import { Modal } from "../../common/ui/modal";

interface Department {
  id: number;
  name: string;
  expense_cost_per_month: string;
  company_name: string;
  company_id: string;
}

interface Company {
  key: string;
  value: string;
}

const DepartmentList: React.FC = () => {
  const [data, setData] = React.useState<Department[]>([]);
  const [companies, setCompanies] = React.useState<Company[]>([]);
  const [isOpen, setOpen] = React.useState<boolean>(false);
  const [editRowId, setEditRowId] = React.useState<number | null>(null);
  const [formData, setFormData] = React.useState<Partial<Department>>({
    expense_cost_per_month: "",
    name: "",
  });

  React.useEffect(() => {
    // Fetch data from API using Axios
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${constants.config.apiBaseUrl}/api/v1/company/departments`
        );
        setData(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleEditClick = (
    event: MouseEvent<HTMLButtonElement>,
    row: Department
  ) => {
    event.preventDefault();
    setEditRowId(row.id);

    const formValues = {
      name: row.name,
      expense_cost_per_month: row.expense_cost_per_month,
    };

    setFormData(formValues);
  };

  const handleEditFormChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    const newFormData = { ...formData };
    (newFormData as any)[name] = value;

    setFormData(newFormData);
  };

  const handleSaveClick = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    try {
      await axios.put(
        `${constants.config.apiBaseUrl}/api/v1/users/employee/${editRowId}`,
        {
          name: formData.name,
          expense_cost_per_month: Number(formData.expense_cost_per_month),
          company_id: Number(formData.company_id),
        }
      );

      setData((prevData) =>
        prevData.map((department) =>
          department.id === editRowId
            ? { ...department, ...formData }
            : department
        )
      );

      setEditRowId(null);
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const handleCancelClick = () => {
    setEditRowId(null);
    setFormData({
      name: "",
      expense_cost_per_month: "",
      company_id: "",
      company_name: "",
    });
  };
  const findCompanybyId = (id: any) => {
    const company = companies.find((el) => el.key === id);
    return company?.value;
  };

  React.useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const response = await axios.get(
          `${constants.config.apiBaseUrl}/api/v1/company?as_drop=true`
        );
        setCompanies(response.data.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchCompanies();
  }, []);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const response = await axios.post(
      `${constants.config.apiBaseUrl}/api/v1/company/departments`,
      {
        name: formData.name,
        expense_cost_per_month: Number(formData.expense_cost_per_month),
        company_id: Number(formData.company_id),
      }
    );
    if (response && response.data) {
      setData((prevState) => {
        prevState.unshift({
          ...response.data.data,
          company_name:
            companies.find((c) => c.key === response.data.company_id)?.value ||
            "",
        });

        return prevState;
      });
      setFormData({
        name: "",
        expense_cost_per_month: "",
        company_id: "",
        company_name: "",
      });
      setOpen(false);
    }
  };

  return (
    <div>
      <Modal
        title={"Create Department"}
        description=""
        isOpen={isOpen}
        onClose={() => {
          setFormData({
            name: "",
            expense_cost_per_month: "",
            company_id: "",
            company_name: "",
          });
          setOpen(false);
        }}
      >
        <div className="flex justify-center items-center">
          <form
            onSubmit={handleSubmit}
            className="bg-white p-6 rounded shadow-md w-full max-w-md"
          >
            <div className="mb-4">
              <label
                htmlFor="name"
                className="block text-gray-700 font-bold mb-2"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="expense_cost_per_month"
                className="block text-gray-700 font-bold mb-2"
              >
                Expense Cost Per Month
              </label>
              <input
                type="text"
                id="expense_cost_per_month"
                name="expense_cost_per_month"
                value={formData.expense_cost_per_month}
                onChange={handleChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="company_id"
                className="block text-gray-700 font-bold mb-2"
              >
                Company Name
              </label>
              <select
                id="company_id"
                name="company_id"
                value={formData.company_id}
                onChange={handleChange}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              >
                <option value="">Select a company</option>
                {companies.map((company) => (
                  <option key={company.key} value={company.key}>
                    {company.value}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex items-center justify-between">
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <div className="flex border-b pb-2 items-center justify-between">
        <div className="mb-3 flex justify-center">
          <span className="text-lg font-semibold">Department</span>
        </div>
        <div>
          <Button
            size="sm"
            className={`bg-cyan-500 hover:bg-cyan-500`}
            onClick={() => setOpen(true)}
            title="disabled"
          >
            <CirclePlus className="mr-2 h-4 w-4" />
            Add Department
          </Button>
        </div>
      </div>
      <table className="table-auto w-full border-collapse">
        <thead>
          <tr className="bg-gray-200 text-left">
            <th className="py-2 px-4 border-b">Name</th>
            <th className="py-2 px-4 border-b">Company</th>
            <th className="py-2 px-4 border-b">Expense Cost/Month</th>
            <th className="py-2 px-4 border-b">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr key={row.id} className="hover:bg-gray-100">
              {editRowId === row.id ? (
                <>
                  <td className="py-2 px-4 border-b">{row.name}</td>
                  <td className="py-2 px-4 border-b">{row.company_name}</td>
                  <td className="py-2 px-4 border-b">
                    <input
                      type="text"
                      name="expense_cost_per_month"
                      value={formData.expense_cost_per_month}
                      onChange={handleEditFormChange}
                      className="w-full px-3 py-1 border rounded"
                    />
                  </td>
                  <td className="py-2 px-4 border-b">
                    <button
                      type="button"
                      onClick={handleSaveClick}
                      className="mr-2 bg-blue-500 text-white px-4 py-2 rounded"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      onClick={handleCancelClick}
                      className="bg-gray-500 text-white px-4 py-2 rounded"
                    >
                      Cancel
                    </button>
                  </td>
                </>
              ) : (
                <>
                  <td className="py-2 px-4 border-b">{row.name}</td>
                  <td className="py-2 px-4 border-b">
                    {findCompanybyId(row.company_id)}
                  </td>
                  <td className="py-2 px-4 border-b">
                    {row.expense_cost_per_month}
                  </td>

                  <td className="py-2 px-4 border-b">
                    <button
                      type="button"
                      onClick={(event) => handleEditClick(event, row)}
                      className="bg-indigo-500 text-white px-4 py-2 rounded"
                    >
                      Edit
                    </button>
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DepartmentList;
