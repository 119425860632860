import React from "react";
import { MoreHorizontal, SquarePen, Trash } from "lucide-react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { AlertModal } from "../../common/modals/alert-modal";
import { Button } from "../../common/ui/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuTrigger } from "../../common/ui/dropdown-menu";
import { Agency } from "../../types/types";

interface CellActionProps {
  data: Agency;
}
export const CellAction: React.FC<CellActionProps> = ({ data }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  // const dispatch = useDispatch();

  const onDelete = () => {
    // try {
    //   setLoading(true);
    //   dispatch(deleteFlightData(data.id.toString()) as any);
    // } catch (error) {
    //   toast.error('Something went wrong!');
    // } finally {
    //   setLoading(false);
    //   setOpen(false);
    // }
  };

  const handleEditFlight = (data: Agency) => {
    //hello
  };

  return (
    <>
      <AlertModal
        isOpen={open}
        onClose={() => setOpen(false)}
        onConfirm={onDelete}
        loading={loading}
      />
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="h-8 w-8 p-0">
            <span className="sr-only">Open menu</span>
            <MoreHorizontal className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          <DropdownMenuLabel>Actions</DropdownMenuLabel>
          <DropdownMenuItem onClick={() => handleEditFlight(data)}>
            <SquarePen className="mr-2 h-4 w-4" />
            Edit
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => setOpen(true)}>
            <Trash className="mr-2 h-4 w-4" />
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
};
