import { Download } from "lucide-react";
import React from "react";
import { Button } from "../../common/ui/button";
import { DataTable } from "../../common/ui/data-table";
import { Agency } from "../../types/types";
import { columns } from "./columns";
import ExportAgencyDataToExcel from "./ExportFlightDataToExcel";
import PieChart from "../Charts/PieChart";
import { Card } from "../../common/ui/card";
import BarChart from "../Charts/BarChart";
import Dropdown from "../../common/ui/dropdown";
import _ from "lodash";
interface AgencyProps {
  data: Agency[];
}

interface MockData {
  client: string;
  project: string;
  internalCost: number;
  billableAmount: number;
  margin: number;
  workedTime: string;
  decimalHours: number;
  timeInSeconds: number;
}

type ColumnDef<T> = {
  id: string;
  header: string;
  cell: keyof T;
};

const mockData: MockData[] = [
  {
    client: "FifthColor",
    project: "3D Engineering (Internal)",
    internalCost: 124.98,
    billableAmount: 0,
    margin: -124.98,
    workedTime: "2 hrs 30 min",
    decimalHours: 2.5,
    timeInSeconds: 9000,
  },
  {
    client: "RedLine",
    project: "2024 Building Design (Internal)",
    internalCost: 1429.55,
    billableAmount: 0,
    margin: -1429.55,
    workedTime: "32 hrs 12 min",
    decimalHours: 32.2,
    timeInSeconds: 115920,
  },
  {
    client: "RedLine",
    project: "2024 Website",
    internalCost: 880.16,
    billableAmount: 0,
    margin: -880.16,
    workedTime: "15 hrs 28 min",
    decimalHours: 15.47,
    timeInSeconds: 55698,
  },
  {
    client: "FifthColor",
    project: "Event Building Activation",
    internalCost: 51.31,
    billableAmount: 0,
    margin: -51.31,
    workedTime: "53 min",
    decimalHours: 0.88,
    timeInSeconds: 3180,
  },
  {
    client: "FifthColor",
    project: "FifthPress",
    internalCost: 7169.05,
    billableAmount: 0,
    margin: -7169.05,
    workedTime: "125 hrs 18 min",
    decimalHours: 125.3,
    timeInSeconds: 450258,
  },
  {
    client: "FifthColor",
    project: "Third Quarter Ad Campaigns",
    internalCost: 748.9,
    billableAmount: 0,
    margin: -748.9,
    workedTime: "14 hrs 28 min",
    decimalHours: 14.47,
    timeInSeconds: 52080,
  },
  {
    client: "FifthColor",
    project: "Website Maintenance",
    internalCost: 319.85,
    billableAmount: 0,
    margin: -319.85,
    workedTime: "5 hrs 44 min",
    decimalHours: 5.07,
    timeInSeconds: 18242,
  },
  {
    client: "FifthColor",
    project: "Website Maintenance",
    internalCost: 86.3,
    billableAmount: 0,
    margin: -86.3,
    workedTime: "1 hrs 13 min",
    decimalHours: 1.22,
    timeInSeconds: 4396,
  },
  {
    client: "FifthColor",
    project: "IT",
    internalCost: 546.27,
    billableAmount: 0,
    margin: -546.27,
    workedTime: "7 hrs 58 min",
    decimalHours: 8.97,
    timeInSeconds: 32306,
  },
  {
    client: "FifthColor",
    project: "IT",
    internalCost: 1434.93,
    billableAmount: 0,
    margin: -1434.93,
    workedTime: "23 hrs 43 min",
    decimalHours: 23.73,
    timeInSeconds: 85429,
  },
  {
    client: "FifthColor",
    project: "Report Rover - Marketing Automation",
    internalCost: 1100.27,
    billableAmount: 0,
    margin: -1100.27,
    workedTime: "N/A",
    decimalHours: 0,
    timeInSeconds: 0,
  },
];

const projectMarginData = {
  project: "Carbliss - NBCU",
  budgetHours: "82 hrs",
  hours: "2 hrs 5 min",
  cost: 111.26,
  billable: 334.17,
  margin: 222.91,
};

const dataColumns: ColumnDef<MockData>[] = [
  { id: "client", header: "Client", cell: "client" },
  { id: "project", header: "Project", cell: "project" },
  { id: "internalCost", header: "Internal Cost ($)", cell: "internalCost" },
  {
    id: "billableAmount",
    header: "Billable Amount ($)",
    cell: "billableAmount",
  },
  { id: "margin", header: "Margin ($)", cell: "margin" },
  { id: "workedTime", header: "Worked Time", cell: "workedTime" },
  { id: "decimalHours", header: "Decimal Hours", cell: "decimalHours" },
  { id: "timeInSeconds", header: "Time in Seconds", cell: "timeInSeconds" },
];

const AgencyOverview: React.FC<AgencyProps> = ({ data }) => {
  
  const clients = _.uniq(mockData.map(el => el.client))
  const [project, setProject] = React.useState("FifthColor");
  const deleteSelectedData = async (data: Agency[]) => {
    //   const selectedIds = data.map((flight) => flight.id);
    //   console.log('Selected IDs:', selectedIds);
    //   dispatch(deleteFlights(selectedIds) as any)
  };

  return (
    <div>
      <div className="flex border-b pb-2 items-center justify-center">
        <span className="text-lg font-semibold">Agency Overview</span>
      </div>
      <DataTable
        searchKey="annualSalary"
        clickable={true}
        columns={columns}
        data={data}
        dataType={"agency"}
        onDeleteData={deleteSelectedData}
      />
      <div className="my-5 mx-2 mt-10 sm:mx-5 grid sm:grid-cols-2 md:grid-cols-4 gap-5">
        <Card className="flex flex-col items-center p-5">
          <h1 className="font-semibold  pb-2">Capacity</h1>
          <PieChart labels={["ABC", "CDE"]} values={[1, 9]} colors={["gray", "black"]} />
        </Card>
        <Card className="flex flex-col items-center p-5">
          <h1 className="font-semibold  pb-2">Overtime</h1>
          <PieChart labels={["ABC", "CDE"]} values={[4, 16]} colors={["gray", "black"]} />
        </Card>
        <Card className="flex flex-col items-center p-5">
          <h1 className="font-semibold  pb-2">Due Dates</h1>
          <BarChart
            labels={["Past Due", "On Time", "Unassigned"]}
            values={[10, 20, 30]}
            colors={["gray"]}
          />
        </Card>
        <Card className="flex flex-col items-center p-5">
          <h1 className="font-semibold  pb-2">Billables</h1>
          <BarChart
            labels={["Billable Hrs Worked in $", "Actual Billables"]}
            values={[10, 20]}
            colors={["gray"]}
          />
        </Card>
      </div>
      <div className="my-5 mx-2 mt-10 sm:mx-5 grid sm:grid-cols-2 md:grid-cols-4 gap-5">
        <div className="col-span-3 w-11/12 bg-gray-200 p-4 rounded">
          <div className="flex justify-between">
            <div className="font-bold my-4">Non-Billable Project Summary</div>
            <Dropdown
              options={clients}
              selectedOption={project}
              onOptionChange={setProject}
            />
          </div>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white">
              <thead>
                <tr>
                  {dataColumns.map((column) => (
                    <th key={column.id} className="px-4 border">
                      {column.header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {mockData.filter(item => item.client === project ).map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {dataColumns.map((column) => (
                      <td key={column.id} className="px-4 border">
                        {row[column.cell]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex flex-col items-center p-5">
          <h1 className="font-semibold  pb-2">Project Margin</h1>

          <Dropdown
            options={clients}
            selectedOption={project}
            onOptionChange={setProject}
          />

          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="px-4 border">Budget Hours</th>
                <th className="px-4 border">Hours</th>
                <th className="px-4 border">Cost</th>
                <th className="px-4 border">Billable</th>
                <th className="px-4 border">Margin</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="px-4 border">{projectMarginData.budgetHours}</td>
                <td className="px-4 border">{projectMarginData.hours}</td>
                <td className="px-4 border">
                  ${projectMarginData.cost.toFixed(2)}
                </td>
                <td className="px-4 border">
                  ${projectMarginData.billable.toFixed(2)}
                </td>
                <td className="px-4 border">
                  ${projectMarginData.margin.toFixed(2)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AgencyOverview;
