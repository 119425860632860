import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Loader from "./common/Loader";
import Dashboard from "./components/Dashboard";
import PageTitle from "./components/PageTitle";
import PaymoUsers from "./components/PaymoUsers";

function App() {
  const [loading, setLoading] = useState<boolean>(true);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);

  const routesData = [
    {
      path: "/",
      title: "Home | Paymo Dashboard",
      component: Dashboard,
      exact: true,
    },
    {
      path: "/paymo",
      title: "Home | Paymo Users",
      component: PaymoUsers,
      exact: true,
    },
  ];

  return loading ? (
    <Loader />
  ) : (
    <>
      <Routes>
        {routesData.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              <>
                <PageTitle title={route.title} />
                <route.component />
              </>
            }
            {...(route.exact && { exact: true })}
          />
        ))}
      </Routes>
    </>
  );
}

export default App;
