import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "./global.css";
import { BrowserRouter as Router } from "react-router-dom";
import Cookies from "universal-cookie";
import { Provider } from "react-redux";
import { ToasterProvider } from "./lib/toast-provider";

export const cookies = new Cookies();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    {/* <Provider store={store}> */}
      <ToasterProvider />
      <Router>
        <App />
      </Router>
    {/* </Provider> */}
  </React.StrictMode>
);


