import React from "react";

interface DropdownProps {
  options: string[];
  selectedOption: string;
  onOptionChange: (option: string) => void;
}

const Dropdown: React.FC<DropdownProps> = ({
  options,
  selectedOption,
  onOptionChange,
}) => {
  return (
    <div className="relative inline-block text-left">
      <select
        value={selectedOption}
        onChange={(e) => onOptionChange(e.target.value)}
        className="block w-full py-2 pl-3 pr-10 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
      >
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Dropdown;
