import axios from "axios";
import React, { useState, useEffect, ChangeEvent, MouseEvent } from "react";
import constants from "../../common/constants";

interface Employee {
  employee_id: number;
  paymo_user_id: number;
  name: string;
  email: string;
  workable_hours_per_month: string;
  annual_salary: string;
}

const EmployeeEdit: React.FC = () => {
  const [data, setData] = useState<Employee[]>([]);
  const [editRowId, setEditRowId] = useState<number | null>(null);
  const [formData, setEditFormData] = useState<Partial<Employee>>({
    workable_hours_per_month: "",
    annual_salary: "",
  });

  useEffect(() => {
    // Fetch data from API using Axios
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${constants.config.apiBaseUrl}/api/v1/users/employee`
        );
        setData(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleEditClick = (
    event: MouseEvent<HTMLButtonElement>,
    row: Employee
  ) => {
    event.preventDefault();
    setEditRowId(row.paymo_user_id);

    const formValues = {
      annual_salary: row.annual_salary,
      workable_hours_per_month: row.workable_hours_per_month,
    };

    setEditFormData(formValues);
  };

  const handleEditFormChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    console.log(name, value);
    const newFormData = { ...formData };
    (newFormData as any)[name] = value;

    setEditFormData(newFormData);
  };

  const handleSaveClick = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    console.log("Saving data:", formData);

    try {
      await axios.put(
        `${constants.config.apiBaseUrl}/api/v1/users/employee/${editRowId}`,
        {
          
          workable_hours_per_month: Number(formData.workable_hours_per_month),
          annual_salary: Number(formData.annual_salary),
        }
      );

      setData((prevData) =>
        prevData.map((user) =>
          user.paymo_user_id === editRowId ? { ...user, ...formData } : user
        )
      );

      setEditRowId(null);
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const handleCancelClick = () => {
    setEditRowId(null);
  };

  return (
    <table className="table-auto w-full border-collapse">
      <thead>
        <tr className="bg-gray-200 text-left">
          <th className="py-2 px-4 border-b">Name</th>
          <th className="py-2 px-4 border-b">Email</th>
          <th className="py-2 px-4 border-b">Annual Salary</th>
          <th className="py-2 px-4 border-b">Workable Hours/Month</th>
          <th className="py-2 px-4 border-b">Actions</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row) => (
          <tr key={row.paymo_user_id} className="hover:bg-gray-100">
            {editRowId === row.paymo_user_id ? (
              <>
                <td className="py-2 px-4 border-b">{row.name}</td>
                <td className="py-2 px-4 border-b">{row.email}</td>
                <td className="py-2 px-4 border-b">
                  <input
                    type="text"
                    name="annual_salary"
                    value={formData.annual_salary}
                    onChange={handleEditFormChange}
                    className="w-full px-3 py-1 border rounded"
                  />
                </td>
                <td className="py-2 px-4 border-b">
                  <input
                    type="text"
                    name="workable_hours_per_month"
                    value={formData.workable_hours_per_month}
                    onChange={handleEditFormChange}
                    className="w-full px-3 py-1 border rounded"
                  />
                </td>
                <td className="py-2 px-4 border-b">
                  <button
                    type="button"
                    onClick={handleSaveClick}
                    className="mr-2 bg-blue-500 text-white px-4 py-2 rounded"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    onClick={handleCancelClick}
                    className="bg-gray-500 text-white px-4 py-2 rounded"
                  >
                    Cancel
                  </button>
                </td>
              </>
            ) : (
              <>
                <td className="py-2 px-4 border-b">{row.name}</td>
                <td className="py-2 px-4 border-b">{row.email}</td>
                <td className="py-2 px-4 border-b">{row.annual_salary}</td>
                <td className="py-2 px-4 border-b">
                  {row.workable_hours_per_month}
                </td>
                <td className="py-2 px-4 border-b">
                  <button
                    type="button"
                    onClick={(event) => handleEditClick(event, row)}
                    className="bg-indigo-500 text-white px-4 py-2 rounded"
                  >
                    Edit
                  </button>
                </td>
              </>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default EmployeeEdit;
