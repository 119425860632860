import { Lock, User } from "lucide-react";
import React from "react";
import { Card } from "../common/ui/card";
import DateRangePicker, {
  DateType,
  DateValueType,
} from "react-tailwindcss-datepicker";
import { agencyMockData } from "../common/ui/data/data";
import { Separator } from "../common/ui/separator";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../common/ui/tabs";
import DefaultLayout from "../layout/DefaultLayout";
import AgencyOverview from "./agency/AgencyOverview";
import BarChartComponent from "./Charts/BarChart";
import PieChart from "./Charts/PieChart";
import LineChart from "./Charts/LineChart";
import dayjs from "dayjs";

const Dashboard = () => {
  const [dateRange, setDateRange] = React.useState<{
    startDate: DateType | null;
    endDate: DateType | null;
  }>({ startDate: new Date(), endDate: new Date() });

  const onDateRangeChange = (
    value: DateValueType,
    e?: HTMLInputElement | null | undefined
  ) => {
    setDateRange({
      startDate: value?.startDate || null,
      endDate: value?.endDate || null,
    });
  };
  return (
    <DefaultLayout>
      <div className="flex justify-end">
        <div className="basis-1/4">
          <DateRangePicker value={dateRange} onChange={onDateRangeChange} />
        </div>
      </div>
      <Tabs defaultValue="agency" className="mx-5">
        <TabsList className="grid grid-cols-3 h-full w-full md:w-[50%]">
          <TabsTrigger value="agency" className="py-2">
            Agency
          </TabsTrigger>
          <TabsTrigger value="department" className="py-2">
            Department
          </TabsTrigger>
          <TabsTrigger value="individual" className="py-2">
            Individual
          </TabsTrigger>
        </TabsList>
        <TabsContent value="agency">
          <Card className="p-5 my-5">
            <div>
              {/* <div className="mb-3 flex justify-center">
                <span className="text-lg font-semibold">Agency Overview</span>
              </div> */}
              <div className="py-2 pb-4">
                <AgencyOverview data={agencyMockData} />
              </div>
            </div>
          </Card>
        </TabsContent>
        <TabsContent value="department">
          <Card className="p-5 my-5">
            <div className="w-full">
              <div className="mb-3 flex justify-center">
                <span className="text-lg font-semibold text-center">
                  Department Overview
                </span>
              </div>
              <div className="spaye-y-4 py-2 pb-4 w-96">Department Table</div>
            </div>
          </Card>
        </TabsContent>
        <TabsContent value="individual">
          <Card className="p-5 my-5">
            <div>
              <div className="mb-3 flex justify-center">
                <span className="text-lg font-semibold">
                  Individual Overview
                </span>
              </div>
              <div className="spaye-y-4 py-2 pb-4 w-96">Individual Table</div>
            </div>
          </Card>
        </TabsContent>
      </Tabs>
    </DefaultLayout>
  );
};

export default Dashboard;
