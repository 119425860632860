import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { ArrowUpDown } from "lucide-react";
import { Checkbox } from "../../common/ui/checkbox";
import { CellAction } from "./cell-actions";
import { Button } from "../../common/ui/button";
import { Agency } from "../../types/types";

export const columns: ColumnDef<Agency>[] = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
        // disabled={
        //   row.original.flightStatus === 'success' ||
        //   row.original.flightStatus === 'inprogress'
        // }
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },

  {
    accessorKey: "annualSalary",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="h-fit"
        >
          <p className="text-wrap">Annual Salary</p>
        </Button>
      );
    },
  },
  {
    accessorKey: "workableHours",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="h-fit"
        >
          <p className="text-wrap">Workable Hours</p>
        </Button>
      );
    },
  },
  {
    accessorKey: "buildingAllocation",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="h-fit"
        >
          <p className="text-wrap">Building Allocation</p>
        </Button>
      );
    },
  },
  {
    accessorKey: "expenseCost",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="h-fit"
        >
          <p className="text-wrap">Expense Cost</p>
        </Button>
      );
    },
  },
  {
    accessorKey: "monthlyWage",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="h-fit"
        >
          <p className="text-wrap">Monthly Wage</p>
        </Button>
      );
    },
  },
  {
    accessorKey: "hourlyRate",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="h-fit"
        >
          <p className="text-wrap">Hourly Rate</p>
        </Button>
      );
    },
  },
  {
    accessorKey: "fullyLoadedCost",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="h-fit"
        >
          <p className="text-wrap">Fully Loaded Cost</p>
        </Button>
      );
    },
  },
  {
    accessorKey: "hoursInMonth",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="h-fit"
        >
          <p className="text-wrap">Hours In Month</p>
        </Button>
      );
    },
  },
  {
    accessorKey: "ptoHours",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="h-fit"
        >
          <p className="text-wrap">PTO Hours</p>
        </Button>
      );
    },
  },
  {
    accessorKey: "utiWorkableHours",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="h-fit"
        >
          <p className="text-wrap">Workable Hours</p>
        </Button>
      );
    },
  },
  {
    accessorKey: "trackedHours",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="h-fit"
        >
          <p className="text-wrap">Tracked Hours</p>
        </Button>
      );
    },
  },
  {
    accessorKey: "unTrackedHours",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="h-fit"
        >
          <p className="text-wrap">Untracked Hours</p>
        </Button>
      );
    },
  },
  {
    accessorKey: "trackedPercent",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="h-fit"
        >
          <p className="text-wrap">Tracked Percent</p>
        </Button>
      );
    },
  },
  {
    accessorKey: "nonBillableHours",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="h-fit"
        >
          <p className="text-wrap">Non Billable Hours</p>
        </Button>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: "nonBillablePercent",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="h-fit"
        >
          <p className="text-wrap">Non Billable Percent</p>
        </Button>
      );
    },
  },

  {
    accessorKey: "nonBillableCost",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="h-fit"
        >
          <p className="text-wrap">Non Billable Cost</p>
        </Button>
      );
    },
  },
  {
    accessorKey: "billableHours",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="h-fit"
        >
          <p className="text-wrap">Billable Hours</p>
        </Button>
      );
    },
  },
  {
    accessorKey: "billablePercent",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="h-fit"
        >
          <p className="text-wrap">Billable Percent</p>
        </Button>
      );
    },
  },
  {
    accessorKey: "billableCost",
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
          className="h-fit"
        >
          <p className="text-wrap">Billable Cost</p>
        </Button>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    id: "actions",
    cell: ({ row }) => <CellAction data={row.original} />,
  },
];
