import React from "react";
import { Card } from "../common/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../common/ui/tabs";
import DefaultLayout from "../layout/DefaultLayout";
import PaymoUserEdit from "./PaymoUser/PaymoUserEdit";
import EmployeeEdit from "./Employee/EmployeeEdit";
import DepartmentList from "./company/Department";
import CompanyList from "./company/Company";
const PaymoUsers = () => {
  return (
    <DefaultLayout>
      <Tabs defaultValue="paymo" className="my-5 mx-5">
        <TabsList className="grid grid-cols-4 h-full w-full md:w-[50%]">
          <TabsTrigger value="paymo" className="py-2">
            Paymo Users
          </TabsTrigger>
          <TabsTrigger value="employee" className="py-2">
            Employee
          </TabsTrigger>
          <TabsTrigger value="departments" className="py-2">
            Departments
          </TabsTrigger>
          <TabsTrigger value="company" className="py-2">
            Company
          </TabsTrigger>
        </TabsList>

        <TabsContent value="paymo">
          <Card className="p-5 my-5">
            <div>
              <div className="py-2 pb-4">
                <PaymoUserEdit />
              </div>
            </div>
          </Card>
        </TabsContent>
        <TabsContent value="employee">
          <Card className="p-5 my-5">
            <div>
              <div className="py-2 pb-4">
                <EmployeeEdit />
              </div>
            </div>
          </Card>
        </TabsContent>
        <TabsContent value="company">
          <Card className="p-5 my-5">
            <div>
              <CompanyList />
            </div>
          </Card>
        </TabsContent>
        <TabsContent value="departments">
          <Card className="p-5 my-5">
            <div>
              <div className="py-2 pb-4">
                <DepartmentList />
              </div>
            </div>
          </Card>
        </TabsContent>
      </Tabs>
    </DefaultLayout>
  );
};

export default PaymoUsers;
